import React, { ReactNode, FC, useState } from "react"
import styled from "@emotion/styled"

import {
  Body,
  BodyBold,
  colours,
  ExpandableCard,
  horizontal,
  TextLink,
  typographyStyles,
  vertical,
} from "design-kit"

const P = styled(Body)`
  margin: ${vertical.xs} 0;
`

const Bold = styled(BodyBold)`
  margin: ${vertical.xs} 0;
`

const InlineBold = styled.span`
  ${typographyStyles.bodyBold};
`

const Li = styled.li`
  margin: ${vertical.xs} 0;
  margin-left: ${horizontal.m};
  ${typographyStyles.body};
  list-style: disc;
  padding-left: ${horizontal.xs};
`

const Example = styled.div`
  padding-left: ${horizontal.s};
  border-left: 4px solid ${colours.greyScale.grey50};
`

interface FAQItem {
  id: string
  title: string
  content: ReactNode
  isExpanded: boolean
}

const newmoFaqItems = (expandedId: string | null): FAQItem[] => [
  {
    id: "newmo-a",
    isExpanded: expandedId === "newmo-a",
    title: "What does this mortgage calculator show me?",
    content: (
      <React.Fragment>
        <P>This mortgage calculator shows you:</P>
        <ul>
          <Li>The largest amount you could borrow</Li>
          <Li>The value of the most expensive property you could afford</Li>
        </ul>
        <P>
          We work out the largest amount you could borrow based on your income
          only.
        </P>
        <P>
          We work out the value of the most expensive property you could afford
          by adding your deposit to the largest amount you can borrow.
        </P>
        <P>
          How much you can borrow depends on the size of your deposit. To
          qualify for a mortgage, you need a deposit of at least 5% of the
          property’s value. So, to buy property worth £250,000, you’ll need to
          pay at least £12,500 out of your own pocket.
        </P>
        <P>
          If you have a bigger deposit, you may be able to afford a more
          expensive home (more on this in a minute).
        </P>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-b",
    isExpanded: expandedId === "newmo-b",
    title: "How much can I borrow?",
    content: (
      <React.Fragment>
        <P>
          As a rule, most lenders will let you borrow 4.5 times your total
          annual income. So, if you earn £50,000 a year, you’ll usually be able
          to borrow up to £225,000.
        </P>
        <P>
          Your annual income isn’t just your basic salary. It’s all the income
          you earn in a given tax year. Most lenders have their own rules around
          what they’ll accept as income. But, typically, the following count as
          income, as long as you have the documents to prove it:
        </P>
        <ul>
          <Li>Any bonuses, commissions, overtime, and other allowances</Li>
          <Li>Income from a part-time job</Li>
          <Li>Government benefits, bursaries, and grants</Li>
          <Li>Pension income</Li>
          <Li>Any interest you earn on your investments</Li>
          <Li>
            Your cut of the profits from any limited company you own shares in
          </Li>
          <Li>Rental income from other properties</Li>
          <Li>Income from overseas</Li>
        </ul>
        <P>
          If your income changes from one year to another, enter your average
          income over the last two years. This should be enough to give you a
          rough idea of how much you could borrow.
        </P>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-c",
    isExpanded: expandedId === "newmo-c",
    title: "Are there other factors that affect how much I can borrow?",
    content: (
      <React.Fragment>
        <P>
          Yes. While your income is the single biggest factor affecting how much
          you can borrow, your expenses can also make a difference. Lenders want
          to make sure you’ll be able to keep affording your mortgage, so
          they’ll also want to know about:
        </P>
        <ul>
          <Li>Any outstanding loans and credit card debt</Li>
          <Li>
            Your bills – Council Tax, utilities, mobile phone contracts, and
            insurance policies
          </Li>
          <Li>
            How much you spend on travel, including the cost of running your car
          </Li>
          <Li>
            Whether you have kids and how much it costs to care for them,
            including your share of childcare costs and school fees
          </Li>
          <Li>
            Whether you pay spousal maintenance (for example because you’re
            separated or divorced) and how much you pay
          </Li>
        </ul>
        <P>
          If your expenses make up a very big chunk of your income – this is
          known as your debt-to-income ratio – lenders may decide to lend you a
          smaller amount than they otherwise would. This is because they may
          take the view that a larger repayment would put your finances under
          too much strain.
        </P>
        <P>
          With this in mind, try paying off as much of your debt as possible
          before you apply for a mortgage.
        </P>
        <P>
          As part of your mortgage application, lenders will also look at your
          credit history. This helps them understand how you’ve handled credit
          in the past and assess how likely you are to default. We’ll talk about
          how your credit history affects your mortgage application in more
          detail below.
        </P>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-d",
    isExpanded: expandedId === "newmo-d",
    title: "Does my age make a difference to how much I can borrow?",
    content: (
      <React.Fragment>
        <P>
          Most standard mortgages are designed to end when you reach retirement
          age. The reason for this is that, once you retire, your income may
          become more limited and mortgage repayments could strain your
          finances.
        </P>
        <P>
          Because of this, lenders will likely offer you shorter repayment terms
          as you get older. So if you want to borrow a large amount, you’ll need
          to prove you can afford to make larger repayments in less time.
        </P>
        <P>
          That said, while getting a mortgage into retirement is trickier, it’s
          not impossible. The key is to prove you have enough income – from your
          pension, investments, or other sources – to comfortably afford the
          repayments.
        </P>
        <P>
          If that’s not an option, you could consider the following
          alternatives:
        </P>
        <ul>
          <Li>
            A guarantor mortgage
            <P>
              This is a mortgage where a trusted family member commits to taking
              over your repayments if you can no longer afford them
            </P>
          </Li>
          <Li>
            An equity release scheme
            <P>
              If you already own a property and you’ve repaid all or most of
              your mortgage, an equity release scheme can help you cash in on
              its value. You can do this in two main ways:
            </P>
            <ul>
              <Li>
                A lifetime mortgage
                <P>
                  Here, you’d take out a mortgage without having to make any
                  repayments. Your lender makes their money back by selling your
                  home when you go into long-term care or pass away.
                </P>
              </Li>
              <Li>
                A home reversion scheme
                <P>
                  Here, you sell all or part of your home to an equity release
                  company. You can continue living in your home until you go
                  into long-term care or pass away, at which point the company
                  will sell it.
                </P>
              </Li>
              <P>
                Bear in mind that you won’t be able to leave your home to your
                family when you pass away if you use an equity release scheme.
              </P>
            </ul>
          </Li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-e",
    isExpanded: expandedId === "newmo-e",
    title:
      "I’m self-employed. Does this make a difference to how much I can borrow?",
    content: (
      <React.Fragment>
        <P>
          The short answer is no. Lenders decide how much you can borrow based
          on your income and expenses, regardless of whether you’re employed or
          self-employed.
        </P>
        <P>
          That said, proving your income is a bit more fiddly when you’re
          self-employed. This is because:
        </P>
        <ul>
          <Li>
            Your income fluctuates from month to month and from one year to
            another
          </Li>
          <Li>
            Unlike employees, you don’t have payslips and a P60 form that prove
            how much you’ve earned in a given tax year
          </Li>
        </ul>
        <P>
          So how do you prove you can afford a mortgage if you’re self-employed?
        </P>
        <P>For starters, you’ll need to produce copies of your SA302 forms.</P>
        <P>
          An SA302 form is a government form that shows your annual income based
          on your self assessment tax return. You can order SA302 forms from
          HMRC through your Government Gateway account or print them off from
          your accounting software.
        </P>
        <P>
          Many lenders will want SA302 forms for at least the last two years
          you’ve been self-employed. This is so they can get a good sense of
          your business’ financial health. There are lenders who accept just one
          year, but this will limit your options.
        </P>
        <P>
          Been trading for less than a year? It may be very difficult to get a
          mortgage, though not impossible.
        </P>
        <P>If you’re:</P>
        <ul>
          <Li>
            A contractor – that is, you work on one project at a time for a
            fixed period and then move on to the next project
          </Li>
          <Li>
            A freelancer who works on a retainer basis – that is, your clients
            pay a fixed monthly fee in exchange for your services
          </Li>
        </ul>
        <P>
          you could try a lender that does contract-based underwriting. Here,
          the lender will look at your contracts and use your day rate (or your
          retainer fees) to work out your annual income.
        </P>
        <P>
          Unfortunately, this won’t be a good option if you sell products or
          services to clients on an as-needed basis.
        </P>
        <P>
          What other documents you’ll need for your mortgage application will
          depend on how your business is set up.
        </P>
        <P>
          If you’re a sole trader, lenders may ask to see your annual accounts.
        </P>
        <P>
          If you’re in business with others, lenders will want to know exactly
          what your share of the profits is. So you should make sure this comes
          out clearly in your accounts.
        </P>
        <P>
          If you’re a limited company, lenders will want to see your company
          accounts.
        </P>
        <P>
          Different lenders will look at different figures. Some will only
          consider your salary and dividends as income. Others may also include
          your company’s retained profits – profits which you haven’t withdrawn
          from the company’s bank account.
        </P>
        <P>
          Needless to say, if a lender takes retained profits into account, your
          income will be bigger and you may be able to borrow more.
        </P>
        <P>
          If you’re self-employed and looking for a mortgage,{" "}
          <TextLink
            text="we can help"
            href="https://www.habito.com/home/self-employed-mortgages"
            target="_blank"
          />
        </P>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-f",
    isExpanded: expandedId === "newmo-f",
    title: "I have a big income, but I can only borrow a little. Why is that?",
    content: (
      <React.Fragment>
        <P>This might have something to do with the size of your deposit.</P>
        <P>
          Most lenders will only lend you up to 95% of your property’s value. So
          if you have £5,000 saved up, you’ll only be able to borrow £100,000,
          even if you earn much more.
        </P>
        <P>
          Try entering a larger deposit amount in the calculator and see what
          happens to the numbers.
        </P>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-g",
    isExpanded: expandedId === "newmo-g",
    title: "I have a big deposit. Why can’t I borrow more?",
    content: (
      <React.Fragment>
        <P>
          Lenders work out how much you can borrow based on your income and
          expenses, not on the size of your deposit.
        </P>
        <P>
          That said, a bigger deposit could make you able to afford a more
          expensive home.
        </P>
        <P>
          Lenders expect you to have a deposit of at least 5% of the property’s
          value. If you have more than 5% saved up, you can use it to make up
          the difference.
        </P>
        <Example>
          <Bold>Example</Bold>
          <P>
            Imagine your lender said they can lend you £170,000 based on your
            income and expenses.
          </P>
          <P>
            Since you must pay a deposit of at least 5%, you can afford a home
            worth £178,950 (the £170,000 you’ll borrow plus a 5% deposit of
            £8,950).
          </P>
          <P>Luckily, you’ve saved £50,000.</P>
          <P>
            This means you could afford a home worth £220,000 (the £170,000 you
            can borrow plus the £50,000 you’ve saved).
          </P>
          <P>Paying a bigger deposit can also get you a cheaper mortgage.</P>
        </Example>
        <Example>
          <Bold>Example</Bold>
          <P>
            Imagine that, instead of buying a more expensive home, you bought
            one worth £178,950, paid the full £50,000 you’ve saved as a deposit,
            and borrowed £128,950.
          </P>
          <P>
            Because you’ve borrowed less compared to what your home is worth –
            that is, you have a lower loan-to-value – your lender may give you a
            better interest rate.
          </P>
          <P>Your monthly repayments will also be smaller.</P>
        </Example>
        <P>We’ll talk about loan-to-value in more detail next.</P>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-h",
    isExpanded: expandedId === "newmo-h",
    title: "What is loan-to-value and why is it important?",
    content: (
      <React.Fragment>
        <P>
          Loan-to-value is how much you’ve borrowed compared to what your home
          is worth.
        </P>
        <P>
          The number is expressed as a percentage. So, if you pay a 5% deposit
          and borrow the rest, your loan-to-value is 95%. And if you pay a 25%
          deposit and borrow the rest, your loan-to-value is 75%.
        </P>
        <P>
          Loan-to-value is important because it tells lenders how much risk
          they’re taking when they lend you money. The higher your
          loan-to-value, the greater the risk to your lender. By contrast, the
          lower the loan-to-value, the lower the risk.
        </P>
        <Example>
          <Bold>Example</Bold>
          <P>Imagine your home was worth £250,000.</P>
          <P>
            Unfortunately, you lose your job and can no longer afford your
            mortgage repayments.
          </P>
          <P>
            If your loan-to-value is 95%, the bank will have to sell your home
            for at least £237,500 to make back what they’ve lent you.
          </P>
          <P>
            If they’re unable to sell your home for that much, they could lose
            money. And if you take the cost of managing your mortgage into
            account – the time mortgage advisors have spent working on your
            application, for instance – the lender may lose money even if they
            sell your home for £237,500.
          </P>
          <P>
            By contrast, if your loan-to-value is 75%, it’s less likely that the
            lender will lose money. They only need to sell for £187,500 to make
            back your loan.
          </P>
        </Example>
        <P>
          Because mortgages with high loan-to-value are riskier for your lender,
          fees and interest are usually higher to offset the risk. You can lower
          your loan-to-value and get a better interest rate and lower fees by
          paying a larger deposit.
        </P>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-i",
    isExpanded: expandedId === "newmo-i",
    title: "Should I borrow the largest amount lenders will give me?",
    content: (
      <React.Fragment>
        <P>
          This depends on your circumstances. But, in general, borrowing at the
          top of your budget is risky.
        </P>
        <P>
          Remember that what you can afford doesn’t just depend on how much a
          lender will let you borrow and the size of your deposit. There are
          also other costs to consider.
        </P>
        <P>
          Firstly, you have to pay your solicitor, any fees your lender charges
          for processing the mortgage, and other upfront costs (more on this in
          a minute). These additional and often unforeseen costs can increase
          the cost of buying a home by 10% or more.
        </P>
        <P>Secondly, think about your new home’s running costs.</P>
        <P>
          A bigger home will usually sit in a higher Council Tax band and cost
          more to light and heat, which means bigger bills. Repairs and
          maintenance may also be more expensive.
        </P>
        <P>
          Lastly, do you have big plans in the pipeline, like getting married or
          quitting your job to start a business?
        </P>
        <P>
          Any of these three issues could affect your ability to keep up with
          your mortgage repayments, so think carefully before you decide how
          much to spend and make sure you borrow responsibly.
        </P>
        <P>
          Experts suggest that your mortgage repayment shouldn’t be more than
          30% of your monthly income.
        </P>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-j",
    isExpanded: expandedId === "newmo-j",
    title: "What other fees do I have to pay when buying a home?",
    content: (
      <React.Fragment>
        <P>
          Alongside your deposit, there are a number of fees you may have to pay
          when you buy a home.
        </P>
        <ul>
          <Li>
            Fees charged by your lender
            <P>
              Lenders charge a series of fees to cover the cost of processing
              your application and setting up your mortgage. These may include:
            </P>
            <ul>
              <Li>
                Booking fees – a one-off fee of around £99 to £250 which you
                have to pay to file your application. This is non-refundable, so
                it’s due even if the mortgage falls through
              </Li>
              <Li>
                Arrangement fees – a fee for setting up your mortgage, usually
                around £999. You only pay this if you go ahead with the mortgage
              </Li>
              <Li>
                Valuation fees – the cost of a valuation survey, usually £150
                and up, though it can reach up to £1,500. The valuation survey
                only checks if you’re paying a fair price for the property. If
                you want to make sure there are no problems with the building
                itself, you’ll need to commission an independent property survey
                for yourself (more on this below)
              </Li>
              <Li>
                Mortgage account fees – a fee for maintaining and, eventually,
                closing your mortgage account, in the region of £300. Some
                lenders call it an exit fee
              </Li>
            </ul>
          </Li>
          <Li>
            Legal fees
            <P>
              This is mainly the cost of hiring a solicitor to do legal work,
              such as checking the property deeds, setting up your mortgage
              security, and transferring the ownership title of the property
              from the seller to you.
            </P>
            <P>
              Fees vary greatly between solicitors and a lot will depend on how
              much work needs to be done, the location of your property, and how
              much it is worth. You can typically expect to pay £1,000 to
              £1,500.
            </P>
          </Li>
          <Li>
            Survey fees
            <P>
              While a property survey is optional, it’s usually a good idea to
              get one done. The survey will make sure there are no serious
              issues that could result in huge repair bills. You can also use
              the results as leverage to negotiate the price down.
            </P>
            <P>
              Home surveys start at £400 and up, but they can save you thousands
              of pounds and grief down the line.
            </P>
          </Li>
          <Li>
            Bank charges
            <P>
              Your lender may bill you for the cost of transferring the mortgage
              money to your solicitor. Similarly, your solicitor may bill you
              for the cost of transferring the money to the seller. You can
              typically expect to pay around £25 to £50.
            </P>
          </Li>
          <Li>
            Other fees
            <P>
              Most lenders will require you to take out buildings insurance as a
              condition of your mortgage. On average, this costs £100 and up a
              year. Depending on your circumstances, you might also have to pay:
            </P>
            <ul>
              <Li>
                Broker’s fees. Some mortgage brokers charge you a fee for their
                services. They should tell you about this upfront
              </Li>
              <Li>
                Agency fees. These apply if you’re selling a property at the
                same time as buying a new one
              </Li>
            </ul>
          </Li>
        </ul>
        <P>
          Overwhelmed by all the moving parts buying a property involves?{" "}
          <TextLink
            text="Habito will sort everything out for you for one straightforward, transparent fee."
            href="/home-buying"
            target="_blank"
          />
        </P>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-k",
    isExpanded: expandedId === "newmo-k",
    title: "What about stamp duty?",
    content: (
      <React.Fragment>
        <P>
          Stamp duty is a tax you have to pay when you buy your main residential
          property.
        </P>
        <P>
          The rate depends on the price of your property and where you live in
          the UK.
        </P>
        <P>
          In England and Northern Ireland, you don’t pay stamp duty on the first
          £125,000. Stamp duty is then due as follows:
        </P>
        <ul>
          <Li>2% on £125,001 to £250,000</Li>
          <Li>5% on £250,001 to £925,000</Li>
          <Li>10% on £925,001 to £1,500,000</Li>
          <Li>12% on anything over £1,500,000</Li>
        </ul>
        <P>
          In Scotland, stamp duty is called land and buildings transaction tax.
          You pay:
        </P>
        <ul>
          <Li>0% on the first £145,000</Li>
          <Li>2% on £145,001 to £250,000</Li>
          <Li>5% on £250,001 to £325,000</Li>
          <Li>10% on £325,001 to £750,000</Li>
          <Li>12% on £750,001 and up</Li>
        </ul>
        <P>In Wales, stamp duty is called land transaction tax. You pay:</P>
        <ul>
          <Li>0% on the first £180,000</Li>
          <Li>3.5% on £180,001 to £250,000</Li>
          <Li>5% on £250,001 to £400,000</Li>
          <Li>7.5% on £400,001 to £750,000</Li>
          <Li>10% on £750,001 to £1,500,000</Li>
          <Li>12% on £1,500,001 and up</Li>
        </ul>
        <P>
          If you’re a first time buyer, and you’re buying in England or Northern
          Ireland, you don’t pay stamp duty on the first £300,000, as long as
          your home doesn’t cost more than £500,000. If your home costs more
          than that, it’s the same stamp duty rules as people who’ve bought a
          home before.
        </P>
        <P>
          Bear in mind, that if you’re buying with someone else, both of you
          have to be first time buyers. Otherwise, you’ll have to pay stamp duty
          at the usual rates.
        </P>
        <P>
          The threshold for first time buyers is also lower in Scotland. Here,
          you don’t pay stamp duty on the first £175,000.
        </P>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-l",
    isExpanded: expandedId === "newmo-l",
    title: "I don’t have a deposit. What can I do?",
    content: (
      <React.Fragment>
        <P>
          You can’t get a mortgage unless you have a deposit of at least 5% of
          the property’s value.
        </P>
        <P>
          That said if you don’t have a deposit right now – or saving for one
          isn’t possible – there are other options you can look into.
        </P>
        <ul>
          <Li>
            Ask your family for help
            <P>Could your family help you with the money for your deposit?</P>
            <P>
              This is becoming increasingly common, especially amongst first
              time buyers. In 2020, one in four home buyers had financial help
              from their parents.
            </P>
            <P>
              Do be aware, though, that your lender may have rules around this.
              For example, some lenders will only allow a certain amount of your
              deposit to come from your family. Some lenders also have rules
              around which family members can gift you money – for example,
              immediate family only.
            </P>
            <P>
              Needless to say, because it’s family, you should also think about
              setting some ground rules. Is the money a gift or a loan? And how
              will you repay it if it’s a loan?
            </P>
            <P>
              It’s good to have clear terms upfront, or things could get
              awkward.
            </P>
          </Li>
          <Li>
            Consider a guarantor mortgage
            <P>
              Some lenders will let you borrow without a deposit if you have a
              guarantor. A guarantor is a family member or trusted friend who
              commits to making your mortgage repayments if you can no longer
              afford them.
            </P>
            <P>
              A guarantor becomes legally responsible for your mortgage if you
              can’t pay it. They’ll also need to use their own home or savings
              as security for your mortgage, so things could get awkward if you
              do start having trouble keeping up with repayments.
            </P>
            <P>
              For this reason, you should make doubly sure you borrow only as
              much as you can afford.
            </P>
            <P>
              It’s also worth noting that having a guarantor doesn’t guarantee
              you’ll be able to get a mortgage without a deposit. Some lenders
              will still require one.
            </P>
          </Li>
          <Li>
            Look into government schemes
            <P>
              The government has three schemes to help you save for a property
              deposit:
            </P>
            <ul>
              <Li>
                Lifetime ISA
                <P>
                  A lifetime ISA is a savings account designed to help you start
                  saving for retirement or for a property deposit. You can save
                  up to £4,000 a year tax-free. The government will kick in £25
                  for every £100 you save, up to a limit of £1,000 a year.
                </P>
                <P>
                  The catch is that you must be under 40 to open one. And you
                  can only use it to pay a property deposit if you’re a first
                  time buyer.
                </P>
              </Li>
              <Li>
                Shared ownership
                <P>
                  This is a scheme that lets you buy a share of your property
                  and pay rent on the rest. To qualify, you must:
                </P>
                <ul>
                  <Li>
                    Have a household income of £80,000 or less (or £90,000 or
                    less if you live in London)
                  </Li>
                  <Li>Show you can’t afford to buy a suitable home</Li>
                  <Li>
                    Be a first time buyer. Or, if you aren’t, you must show you
                    need to move, for example because you’ve separated from your
                    partner, but can’t afford to
                  </Li>
                </ul>
                <P>
                  The shared ownership scheme has its pros and cons, and you
                  should think carefully before considering it.
                </P>
                <P>
                  Things to watch out for include expensive maintenance charges,
                  rising rents, and restrictions on what you can do with the
                  property. Some shared ownership schemes won’t allow you to buy
                  up to 100% of the property, either – so make sure you check
                  with the housing provider before you agree to buy.
                </P>
              </Li>
              <Li>
                Help to buy
                <P>
                  If you’re a first time buyer, you just need to provide a 5%
                  deposit, and the government will provide the rest, up to 20%
                  (up to 40% in London). The government provides this as a loan
                  that’s interest free for 5 years. The catch is that the
                  property must be a new build from a registered home builder.
                </P>
                <P>
                  New builds can be hit or miss. Some buyers of new builds
                  report that their properties came with more problems than they
                  expected. Other buyers have noticed that their new build homes
                  are overpriced, compared to similar properties in the area
                  they bought.
                </P>
                <P>
                  Your lender may carry out a valuation survey to make sure
                  you’re paying a fair price for the property. But it’s also
                  worth booking a home survey. This will confirm whether there
                  are any serious defects or other issues with the building.
                </P>
              </Li>
            </ul>
          </Li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-m",
    isExpanded: expandedId === "newmo-m",
    title: "What mortgage term should I go for?",
    content: (
      <React.Fragment>
        <P>
          Most mortgages last for 25 years, but it’s possible to take out a
          mortgage for either a shorter or longer period. 10 and 15-year terms
          are common for shorter mortgages, while longer mortgages can have 30
          or 35-year terms.
        </P>
        <P>Both have their pros and cons.</P>
        <P>A shorter mortgage means you’ll own your home outright sooner.</P>
        <P>
          The flipside is that you’ll have to make bigger monthly repayments.
          And if lenders think your monthly repayments are too big compared to
          your income and expenses, they may lend you less than you were hoping
          to borrow.
        </P>
        <P>
          Mortgages with longer terms have lower monthly repayments, but you’ll
          pay more interest overall. The Money Advice Service reckons borrowing
          £175,000 at 3% interest over 35 years costs £34,000 more than
          borrowing it over 25 years.
        </P>
        <P>
          A good compromise could be to get a mortgage that has a longer term
          but allows you to make overpayments. This way, you can benefit from
          lower monthly repayments but have the flexibility to pay your mortgage
          off sooner if your financial situation improves.
        </P>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-n",
    isExpanded: expandedId === "newmo-n",
    title: "What’s a good interest rate?",
    content: (
      <React.Fragment>
        <P>
          In a nutshell, a good interest rate is the lowest rate you can get on
          the best possible terms.
        </P>
        <P>The terms are as important as the rate itself.</P>
        <P>
          3% interest over 25 years works out cheaper than 2.7% over 35 years,
          for instance. This is because of compounding – lenders add the
          interest on your mortgage to the principal, so it collects further
          interest. So, the longer you take to pay off your mortgage, the more
          interest you pay.
        </P>
        <P>
          Similarly, whether your interest rate is fixed or variable makes a
          difference.
        </P>
        <P>
          Fixed rates stay unchanged for a set term, usually two, five, or ten
          years. But once that term expires, your lender will put you on their
          standard variable rate, which is much higher.
        </P>
        <P>
          Fixed-rate mortgages also tend to be less flexible. You’ll get charged
          an early repayment fee if you decide to pay off your mortgage during
          the fixed-rate term.
        </P>
        <P>
          Variable rates – and, in turn, your monthly repayment amount – can
          change unexpectedly from one month to another. But your monthly
          repayment won’t necessarily become more expensive. It could also get
          cheaper, because variable rates can go down as well as up.
        </P>
        <P>
          Most lenders advertise their 'typical' or 'representative' APR (annual
          percentage rate). To advertise a rate as 'typical' or
          'representative', at least 51% of the lender’s customers must get that
          rate or lower. This means comparing different lenders’ APRs is a good
          way to find out what rate you can reasonably expect to get.
        </P>
        <P>
          That said, lenders ultimately decide based on your individual
          circumstances, and the single biggest factor in their decision is
          risk.
        </P>
        <P>
          If your mortgage has high loan-to-value – that is, you’ve borrowed a
          big percentage of your home’s value – the lender is more likely to
          lose money if you default. So your interest rate will be higher than
          average.
        </P>
        <P>
          Similarly, if you’ve struggled to pay your debts in the past, the
          lender will give you a higher rate to offset the risk.
        </P>
        <P>
          By contrast, if you have a big deposit and a good credit history,
          you’re likely to be offered a better rate.
        </P>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-o",
    isExpanded: expandedId === "newmo-o",
    title: "I have a bad credit score. Can I still get a mortgage?",
    content: (
      <React.Fragment>
        <P>
          Yes, but it probably won’t be a very good deal. Lenders may ask you to
          put down more than 5% as a deposit. And your interest rate will likely
          be above average as well.
        </P>
        <P>
          Lenders look at your credit history to understand how you’ve handled
          credit in the past. A bad credit score makes you look more risky. So,
          lenders will ask for a bigger deposit and charge higher interest to
          offset this risk.
        </P>
        <P>
          Bear in mind that your credit score may be 'bad' even if you’ve never
          struggled with debt.
        </P>
        <P>
          If you’ve never had a credit card, loan, or contract phone, you’re new
          to the UK, or you’ve just returned after living abroad for a long
          time, you’ll have little or no credit history. Lenders won’t be able
          to check how you’ve handled credit in the past, so they’ll think
          you’re risky even if you aren’t.
        </P>
        <P>
          Every lender has their own expectations when it comes to credit
          scoring.
        </P>
        <P>
          That said, if your score is less than ideal, there’s a greater chance
          they’ll reject your application. So you should talk to us about your
          situation before you apply. This will help you avoid getting rejected
          by multiple lenders, which could make your situation worse.
        </P>
        <P>
          If possible, try waiting before applying for a mortgage, and work on
          improving your credit score in the meantime. You’ll be more likely to
          get approved and on better terms.
        </P>
        <P>Here’s what you can do to improve your credit score:</P>
        <ul>
          <Li>
            Check your Experian, Equifax, and TransUnion (formerly CallCredit)
            credit reports regularly to make sure there are no mistakes. If you
            find any mistakes, ask for them to be fixed.
          </Li>
          <Li>
            Pay your debts in full and on time. This seems incredibly obvious,
            but it’s the single most powerful step you can take to improve your
            credit score. If you struggle to keep track of payment dates, set up
            direct debits wherever possible.
          </Li>
          <Li>
            If you have a limited credit history, get a credit builder card. Use
            it to make small payments and pay them in full every month. It will
            help you improve your score.
          </Li>
          <Li>
            Avoid making several credit applications too close to each other. As
            a rule you should leave around three months between applications. If
            you just want to see if you’re eligible, use an eligibility checker.
            These don’t leave a mark on your credit report and won’t affect your
            score.
          </Li>
          <P>
            <TextLink
              text="Get more tips on improving your credit score here."
              href="https://www.habito.com/hub/article/how-to-improve-your-credit-score"
              target="_blank"
            />
          </P>
        </ul>
        <P></P>
      </React.Fragment>
    ),
  },
  {
    id: "newmo-p",
    isExpanded: expandedId === "newmo-p",
    title:
      "I’m looking for a buy-to-let mortgage... can I use this calculator?",
    content: (
      <React.Fragment>
        <P>
          No – but we do have a{" "}
          <TextLink
            text="buy-to-let mortgage calculator"
            href="/buy-to-let-mortgage-calculator"
            target="_blank"
          />{" "}
          you can use instead! This calculator only shows you how much you could
          borrow to buy a property you’ll live in yourself. Buy-to-let mortgages
          are different.
        </P>
        <P>
          In a{" "}
          <TextLink
            text="buy-to-let mortgage"
            href="https://www.habito.com/home/buy-to-let-mortgages"
            target="_blank"
          />
          , the lender won’t work out how much you can afford to borrow based on
          your income and expenses. They’ll look at how much you could earn by
          renting out the property. This means you can usually borrow more.
        </P>
        <P>
          The flipside is that the lending criteria are much stricter. This is
          because owning a rental property is risky. For example, you might have
          to evict the tenant for bad behaviour and have trouble finding another
          one. This would leave you on the hook for the mortgage repayments,
          plus the rental property’s expenses and your own living expenses.
        </P>
        <P>To qualify for a buy-to-let mortgage, you must:</P>
        <ul>
          <Li>Be 21 or older</Li>
          <Li>Earn at least £25,000 a year</Li>
          <Li>Have a deposit of at least 25% of the property’s value</Li>
          <Li>
            Show the lender you could rent the property for around 25% to 30%
            more than your mortgage repayment
          </Li>
        </ul>
        <P>
          You should also bear in mind that buy-to-let mortgages are likely to
          be interest only, which means your monthly repayments will only cover
          the interest on your mortgage. Once the mortgage term expires, you’ll
          have to either pay off the amount you’ve borrowed or sell the property
          to cover the cost.
        </P>
      </React.Fragment>
    ),
  },
]

const remoFaqItems = (expandedId: string | null): FAQItem[] => [
  {
    id: "remo-a",
    isExpanded: expandedId === "remo-a",
    title: "How to find the best remortgage rates & deals",
    content: (
      <div>
        <P>
          This remortgage calculator will search through thousands of mortgages,
          from over 90 lenders, to find you today’s top deals for the numbers
          you put in.
        </P>
        <P>
          The thing it <InlineBold>won’t</InlineBold> calculate for you is if
          you’re <InlineBold>actually eligible</InlineBold> for the remortgage
          deals you see. That’s because lenders will ask you lots more questions
          before they agree to go ahead with your remortgage.
        </P>
        <P>
          So after you get an idea of what you can save – either with a
          calculator or{" "}
          <TextLink
            text="comparison tool"
            href="/mortgage-comparison"
            target="_blank"
          />{" "}
          – talk to a Habtio expert to apply for the best deal, and feel
          confident you’ll get it.
        </P>
      </div>
    ),
  },
  {
    id: "remo-b",
    isExpanded: expandedId === "remo-b",
    title: "Remortgaging to release equity",
    content: (
      <div>
        <P>
          When it’s time to remortgage, some people take the opportunity to
          borrow more money at the same time.
        </P>
        <P>
          It’s called ‘releasing equity’. You effectively give part of the
          mortgage you’ve already paid off back to the bank, in exchange for
          more money. You can then spend that money on things like home
          improvements, or giving your kids some cash to buy a home.
        </P>
        <P>
          If you’d like to know more,{" "}
          <TextLink
            text="we’ve written a plain English guide to remortgaging to release
            equity"
            href="/hub/article/remortgaging-to-release-equity"
            target="_blank"
          />
          .
        </P>
      </div>
    ),
  },
  {
    id: "remo-c",
    isExpanded: expandedId === "remo-c",
    title: "What is ‘remortgage affordability’ and how do you calculate yours?",
    content: (
      <div>
        <P>
          Your ‘affordability’ is a measure of how much you can afford to borrow
          on a mortgage, and pay each month, while still having enough money
          left over to pay your usual expenses and live comfortably.
        </P>

        <P>
          Before they agree to lend to you, your lender will look at your income
          and spending to make sure it’s a responsible thing to do.
        </P>

        <P>
          It’s a good idea to{" "}
          <TextLink
            text="get your finances into shape"
            href="https://www.habito.com/hub/article/how-to-remortgage"
            target="_blank"
          />{" "}
          before you apply for a remortgage.
        </P>

        <P>
          And if you’re ready to go,{" "}
          <TextLink
            text="you can sign up to chat to a Habito mortgage expert"
            href="/how-can-we-help/remortgage"
            target="_blank"
          />
          , and get a true picture of your affordability.
        </P>
      </div>
    ),
  },
  {
    id: "remo-d",
    isExpanded: expandedId === "remo-d",
    title: "Should you remortgage?",
    content: (
      <div>
        <P>
          Almost always, the answer is <InlineBold>yes</InlineBold>, it’s a good
          idea to remortgage.
        </P>
        <P>
          We know to switch when other introductory deals end, like our phone
          contract or energy bill. But with mortgages, too many people slip onto
          their lender’s default rate after their mortgage introductory period
          is over. If that happens to you, you could end up paying thousands of
          pounds more than you need to.
        </P>
        <P>
          Sometimes, of course, it won’t be the right thing to do – for example,
          if you’re still in your introductory period, or if you have very
          little left to pay on your mortgage. Here’s more about{" "}
          <TextLink
            text="when remortgaging might not make sense"
            href="/home/remortgaging"
            target="_blank"
          />
          .
        </P>
        <P>
          It’s always worth at least checking if you could save money, and our
          remortgage calculator is a great first step. Next, your{" "}
          <TextLink
            text="mortgage expert"
            href="/how-can-we-help/remortgage"
            target="_blank"
          />{" "}
          will do all the maths for you, and tell you for sure.
        </P>
      </div>
    ),
  },
  {
    id: "remo-e",
    isExpanded: expandedId === "remo-e",
    title: "How reliable are remortgage calculators?",
    content: (
      <div>
        <P>
          Most remortgage calculators, including this one, give you a good
          starting estimate of what you could save.
        </P>
        <P>
          You’ll notice this calculator doesn’t ask for many details about you.
          When you actually apply for a remortgage, you’ll have to send your
          lender much more detail about your income, spending and credit
          history. Without that detail, every estimate about what you could save
          is exactly that: an estimate.
        </P>
        <P>
          And remember, no calculator can tell you if you’ll actually be able to
          get a remortgage.
        </P>
        <P>
          Habito can show you what you can really borrow in as little as 15
          minutes.{" "}
          <TextLink
            text="Just create an account"
            href="/how-can-we-help/remortgage"
            target="_blank"
          />
          , tell us what you’re looking for, and your expert will give you a
          reliable figure.
        </P>
      </div>
    ),
  },
  {
    id: "remo-f",
    isExpanded: expandedId === "remo-f",
    title: "When is the latest I can secure a new rate to remortgage?",
    content: (
      <div>
        <P>
          We acknowledge that, especially in times of fluctuating rates, staying
          vigilant for more competitive options is important. When the time
          comes to remortgage, our recommendation is to explore choices across
          the entire market.
        </P>
        <P>
          When considering a switch to a new lender or bank, it's important to
          be aware that unless you stay with your existing lender, a conveyancer
          will be involved in completing the transaction. However, unlike a
          purchase, the remortgage process typically takes between 4 to 6 weeks.
          To ensure a smooth transition and avoid potential pitfalls, we
          recommend starting the remortgaging process well in advance.
        </P>
        <P>
          We’ll reach out to you 3-6 months before your existing mortgage deal
          expires. This time frame allows ample room for us to provide you with
          a suitable recommendation and begin legal proceedings without the risk
          of surpassing your current deal's end date. Going beyond this deadline
          may inadvertently lead to higher costs, as you could end up paying the
          standard variable rate.
        </P>
        <P>
          If you're wondering about the optimal timing for switching rates,
          reach out to us.
        </P>
      </div>
    ),
  },
  {
    id: "remo-g",
    isExpanded: expandedId === "remo-g",
    title: "Do I need a solicitor to remortgage?",
    content: (
      <div>
        <P>
          When it comes to remortgaging, it's important to note that if you
          choose to stay with your existing lender, the process typically does
          not require a solicitor. However, if the decision is made to switch to
          a different lender for a more favourable rate on the market, a
          solicitor is required to complete the transaction, typically taking
          4-6 weeks.
        </P>
        <P>
          Should you opt for a rate switch with your existing lender, this can
          usually be executed within a tight timeframe – typically 48 hours to a
          week before your existing deal expires. Even if you find yourself
          approaching us last minute, rest assured that options are still
          available.
        </P>
        <P>
          Our free mortgage advice services provide you with the opportunity to
          discuss your circumstances and weigh the pros and cons of staying with
          your current lender or exploring options with a new one. Your unique
          situation is at the forefront of our considerations.
        </P>
      </div>
    ),
  },
]

export const RemoFaqs: FC = () => {
  const [expandedId, setExpandedId] = useState<string | null>(null)
  return (
    <ExpandableCard
      items={remoFaqItems(expandedId)}
      onChange={id => {
        setExpandedId(expandedId === id ? null : id)
      }}
    />
  )
}

export const NewmoFaqs: FC = () => {
  const [expandedId, setExpandedId] = useState<string | null>(null)
  return (
    <ExpandableCard
      items={newmoFaqItems(expandedId)}
      onChange={id => {
        setExpandedId(expandedId === id ? null : id)
      }}
    />
  )
}
