import { breakpoints, useHasMounted, useMediaQuery } from "design-kit"
import React from "react"

import Footer from "../shared-components/Footer"
import Navbar from "../shared-components/Navbar"
import { HomepageHead, HomepageBase } from "../views/Homepage/Base"
import { Hero } from "../views/Homepage/Hero"
import { HowItWorks } from "../views/Homepage/HowItWorks"
import { Reviews } from "../views/Homepage/Reviews"
import { NewsletterSignup } from "../views/Homepage/NewsletterSignup"

export const Head: React.FunctionComponent = () => (
  <HomepageHead variant="sunrise" />
)

const Homepage: React.FunctionComponent = () => {
  const scrollRef = React.useRef<HTMLDivElement>(null)

  const hasMounted = useHasMounted()

  const isDesktop = useMediaQuery(breakpoints.desktop.query)

  return (
    <HomepageBase>
      <Navbar
        key={`${hasMounted}`}
        theme={isDesktop ? "opaque" : "transparent-dark"}
      />

      <main id="main-content">
        <Hero
          ctaCopy="How it works"
          scrollRef={scrollRef}
          subtitle="Your mortgage, legal work and property survey – all in one, peaceful place."
          title="Home-buying made easier"
        />

        <HowItWorks scrollRef={scrollRef} />

        <Reviews />

        <NewsletterSignup />
      </main>

      <Footer />
    </HomepageBase>
  )
}

export default Homepage
