import React from "react"
import { css } from "@emotion/react"

import { vertical } from "design-kit"
import {
  WrappedHead,
  WrappedBase,
} from "../shared-components/Shared/WrappedBase"
import Footer from "../shared-components/Footer"
import Navbar from "../shared-components/Navbar"
import { Hero } from "../views/HomeBuying/Hero"
import { WhatWeOffer } from "../views/HomeBuying/WhatWeOffer"
import { HowItWorks } from "../views/HomeBuying/HowItWorks"
import { FeeCalculator } from "../views/HomeBuying/FeeCalculator"
import { FAQs } from "../views/HomeBuying/FAQs"
import { Reviews } from "../views/HomeBuying/Reviews"

import config from "../../config.json"

const PageContent: React.FC = () => {
  return (
    <React.Fragment>
      <Navbar />

      <main
        id="main-content"
        css={css`
          padding-top: ${vertical.xl};
          overflow: hidden;
        `}
      >
        <Hero />
        <WhatWeOffer />
        <HowItWorks />
        <FeeCalculator />
        <FAQs />
        <Reviews />
      </main>

      <Footer />
    </React.Fragment>
  )
}

export const Head: React.FC = () => (
  <WrappedHead
    metaTitle="Your complete home-buying service | Habito"
    metaDescription="Relax while we sort everything – the mortgage, conveyancing, property surveys and legal work. It's never been easier to buy a home."
    canonicalUrl="https://www.habito.com/home-buying"
    noIndex={false}
    pageName="home-buying"
    intercom={true}
    config={config}
  />
)

const HomeBuyingPage: React.FC = () => (
  <WrappedBase>
    <PageContent />
  </WrappedBase>
)

export default HomeBuyingPage
