import { FC } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  Body,
  GridRow,
  Headline2,
  colours,
  column,
  typographyStyles,
  vertical,
} from "design-kit"

import Navbar from "../../shared-components/Navbar"
import {
  WrappedHead,
  WrappedBase,
} from "../../shared-components/Shared/WrappedBase"
import Hero from "../../shared-components/Shared/Hero"
import IndividualVsCompany from "../../views/BorrowWithUs/CompanyBuyToLet/IndividualVsCompany"
import ExplainerCards from "../../views/BorrowWithUs/CompanyBuyToLet/ExplainerCards"
import Footer from "../../shared-components/Footer"

import AboutCard from "../../shared-components/AboutCard"

import config from "../../../config.json"

const NarrowColumn = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 7,
  })}
`
const Narrow: FC<{ children?: React.ReactNode }> = ({ children }) => (
  <GridRow>
    <NarrowColumn>{children}</NarrowColumn>
  </GridRow>
)

const WideColumn = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })}
`
const Wide: FC<{ children?: React.ReactNode }> = ({ children }) => (
  <GridRow>
    <WideColumn>{children}</WideColumn>
  </GridRow>
)

const Section = styled.section`
  margin-bottom: ${vertical.l};
`

const SectionHeading = styled(Headline2)`
  ${typographyStyles.headline3};
  margin: 0 0 ${vertical.s};
`

const Copy = styled(Body)`
  margin: 0 0 ${vertical.xs};
`

export const Head: FC = () => (
  <WrappedHead
    metaTitle="Company buy-to-lets: are they really better? | Habito"
    metaDescription="Company buy-to-lets are becoming more and more popular. But are they really the better choice?"
    canonicalUrl="https://www.habito.com/borrow-with-us/company-buy-to-let"
    noIndex={false}
    pageName="borrow_with_us_company_buy_to_let"
    intercom={true}
    config={config}
  />
)

const CompanyBuyToLet: FC = () => (
  <WrappedBase>
    <div
      css={css`
        background-color: ${colours.white};
      `}
    >
      <Navbar sticky={true} />

      <main>
        <Hero title="Company buy-to-lets: are they really better?">
          <Copy>
            It’s becoming more and more popular for landlords in the UK to buy
            properties through limited companies, rather than ‘as individuals’.
            But is it really the better choice?
          </Copy>

          <Copy>
            The short answer is: it depends. There’s lots of things to factor
            in: how many other properties you own, how you’d like to manage your
            rental income, how long you think you’ll own the property for...
            just to name a few.
          </Copy>

          <Copy>
            A recent tax change – which means that individual landlords will
            soon have to pay more tax – could explain why more people are
            setting up companies to buy. But it’s not necessarily the best
            option for everyone.
          </Copy>

          <Copy>
            Here are some of the main things to consider when you’re deciding
            how to purchase your buy-to-let.
          </Copy>
        </Hero>

        <Wide>
          <Section>
            <AboutCard
              title="Want a company buy-to-let mortgage?"
              content="Habito is here to end mortgage hell. Chat to our team of friendly mortgage experts, and we'll sort your buy-to-let mortgage with zero hassle. And all for free."
              href="https://www.habito.com/how-can-we-help/new-mortgage"
            />
          </Section>
        </Wide>

        <Narrow>
          <Section>
            <SectionHeading>
              Individual vs company: how do they compare?
            </SectionHeading>

            <IndividualVsCompany />
          </Section>
        </Narrow>

        <Wide>
          <Section>
            <SectionHeading>
              More buy-to-let tools &amp; articles
            </SectionHeading>
            <ExplainerCards />
          </Section>
        </Wide>
      </main>

      <Footer />
    </div>
  </WrappedBase>
)

export default CompanyBuyToLet
