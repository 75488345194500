import * as React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  CookieName,
  getLocalStorage,
  setLocalStorageIfConsentGranted,
} from "@heyhabito/cookie-consent"

import {
  Card,
  GridRow,
  HandPointDownIcon,
  borderRadii,
  breakpoints,
  colours,
  column,
  horizontal,
  media,
  mixins,
  resets,
  typographyStyles,
  useHasMounted,
  useMediaQuery,
  vertical,
  iconSize,
} from "design-kit"

import { useSentry } from "@heyhabito/sentry"

import { UserContext } from "../../../shared-components/Shared/UserProvider"
import bgDesktop from "../assets/bg_hero_animated_desktop.jpg"
import bgDesktopRetina from "../assets/bg_hero_animated_desktop_retina.jpg"
import bgMobile from "../assets/bg_hero_animated_mobile.jpg"
import bgMobileRetina from "../assets/bg_hero_animated_mobile_retina.jpg"
import bgTablet from "../assets/bg_hero_animated_tablet.jpg"
import bgTabletRetina from "../assets/bg_hero_animated_tablet_retina.jpg"

import { MainCTAs } from "./MainCTAs"
import { PausePlayButton } from "./PausePlayButton"
import { scrollToHowItWorks } from "./utils"

const videoFallbackGradient = `linear-gradient(
  ${colours.gradientBase.mistyViolet},
  #98d9ff
)`

const VideoOverlay = styled.div`
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.1;
    background-color: ${colours.offBlack};
  }
`

const Video = styled.video`
  height: 75vh;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  width: auto;

  // Ensure the video always fills its box, while preserving the aspect ratio
  object-fit: cover;

  ${breakpoints.desktop`
      height: auto;
    `}
`

const VideoFallback = styled.div`
  ${mixins.gradientBackground({
    desktopBackgroundImage: bgDesktop,
    desktopBackgroundImageRetina: bgDesktopRetina,
    desktopFallbackGradient: videoFallbackGradient,
    fallbackColor: colours.gradientBase.mistyViolet,
    mobileBackgroundImage: bgMobile,
    mobileBackgroundImageRetina: bgMobileRetina,
    mobileFallbackGradient: videoFallbackGradient,
    tabletBackgroundImage: bgTablet,
    tabletBackgroundImageRetina: bgTabletRetina,
    tabletFallbackGradient: videoFallbackGradient,
  })}

  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;

  ${breakpoints.desktop`
    background-position: center;
  `}
`

const cookieName: CookieName = "videoIsPaused"
const storePausedState = (isPaused: boolean): void => {
  if (typeof localStorage !== "undefined") {
    setLocalStorageIfConsentGranted(cookieName, isPaused.toString())
  }
}

const getPausedState = (): boolean | null => {
  const storedState = getLocalStorage(cookieName)
  switch (storedState) {
    case "true":
      return true
    case "false":
      return false
    default:
      return null
  }
  return null
}
const storedPausedState = getPausedState()

const getVideoSrc = (
  viewport: "desktop" | "tablet" | "mobile",
  format: "mp4" | "webm"
): string =>
  `https://assets.habito.com/homepage/hero-animation-2021-12-20-${viewport}.${format}`

export const Hero: React.FC<{
  scrollRef: React.RefObject<HTMLDivElement>
  title: string
  subtitle: string | React.ReactElement
  ctaCopy: string
}> = ({ scrollRef, title, subtitle, ctaCopy }) => {
  const { logToSentryDetailed } = useSentry()
  const hasMounted = useHasMounted()
  const allowsMotion = useMediaQuery(media.allowsMotion.query)
  const isDesktop = useMediaQuery(breakpoints.desktop.query)
  const isTablet = useMediaQuery(breakpoints.tablet.query) && !isDesktop
  const viewport = isDesktop ? "desktop" : isTablet ? "tablet" : "mobile"
  const [isPaused, setPausedState] = React.useState(storedPausedState || false)
  const videoShouldPlay = allowsMotion && !isPaused

  const videoRef = React.useRef<HTMLVideoElement>(null)

  const setIsPaused = (b: boolean): void => {
    setPausedState(b)
    storePausedState(b)
  }

  /**
   * Chrome does not seem to respect media queries for <video> source>s, so we
   * need to manually update the <source>s whenever the viewport changes
   */
  React.useEffect(() => {
    const video = videoRef.current

    if (hasMounted && video !== null) {
      while (
        video.firstElementChild &&
        // Always keep image fallback
        video.childElementCount > 1
      ) {
        video.removeChild(video.firstElementChild)
      }

      const newVideoMP4Source = document.createElement("source")

      newVideoMP4Source.src = getVideoSrc(viewport, "mp4")
      newVideoMP4Source.type = "video/mp4"

      video.prepend(newVideoMP4Source)

      const newVideoWebMSource = document.createElement("source")

      newVideoWebMSource.src = getVideoSrc(viewport, "webm")
      newVideoWebMSource.type = "video/webm"

      video.prepend(newVideoWebMSource)

      video.load()
    }
  }, [viewport, hasMounted, allowsMotion])

  /**
   * Pause/play background video
   */
  React.useEffect(() => {
    const video = videoRef.current

    if (video !== null) {
      if (videoShouldPlay) {
        const playPromise = video.play()

        /**
         *  "[...] in earlier versions of the HTML specification, play() didn't
         *  return a value."
         *
         * https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide,
         * "Example: Handling play() failures" section
         */
        if (playPromise !== undefined) {
          playPromise.catch(err => {
            // Do not play video if autoplay is disabled
            if (err.name === "NotAllowedError") {
              setIsPaused(true)
            }
            // Do not log NotAllowedError (most likely autoplay is disabled)
            // and AbortError (play aborted by another load or pause)
            if (err.name !== "NotAllowedError" && err.name !== "AbortError") {
              logToSentryDetailed({
                description: `video.play promise failed`,
                info: { cause: err, location, identifier: "Homepage" },
                tags: {
                  specific_error: `video.play promise failed: ${err.name}`,
                },
              })
            }
          })
        }
      } else {
        video.pause()
      }
    }
  }, [videoShouldPlay, viewport, hasMounted])

  return (
    <section
      aria-label="Habito: mortgages made easier"
      css={css`
        background-color: ${colours.gradientBase.mistyViolet};
        background-image: ${videoFallbackGradient};
        position: relative;
      `}
    >
      {hasMounted &&
        (allowsMotion ? (
          <VideoOverlay>
            <Video
              disablePictureInPicture
              loop
              muted
              playsInline
              ref={videoRef}
            >
              <VideoFallback />
            </Video>
          </VideoOverlay>
        ) : (
          <VideoFallback />
        ))}

      <GridRow>
        <div
          css={css`
            ${column({
              offsetDesktop: 2,
              widthMobile: 4,
              widthTablet: 6,
              widthDesktop: 8,
            })}
          `}
        >
          <UserContext.Consumer>
            {userEmail => (
              <div
                css={css`
                  margin: ${vertical.xxl} auto ${vertical.xl};
                  max-width: 640px;

                  ${breakpoints.desktop`
                    margin-bottom: ${vertical.xxl};
                    margin-top: calc(${userEmail ? 99 : 88}px + ${
                      vertical.xxl
                    });
                    width: 100%;
                  `}
                `}
              >
                <Card
                  element="div"
                  css={css`
                    padding: ${vertical.m} ${horizontal.m};
                    text-align: center;

                    ::before {
                      border-radius: 40px;
                      opacity: 0.8;
                    }

                    ${breakpoints.tablet`
                      padding: ${vertical.l} 80px;
                    `}
                  `}
                  kind="semi-transparent"
                >
                  <h1 css={css(typographyStyles.statement2)}>{title}</h1>

                  <p
                    css={css`
                      ${typographyStyles.headlineLarge}

                      margin: ${vertical.xs} auto ${vertical.s};
                      max-width: 440px;

                      ${breakpoints.desktop`
                        max-width: unset;
                      `}
                    `}
                  >
                    {subtitle}
                  </p>

                  <MainCTAs />
                </Card>
              </div>
            )}
          </UserContext.Consumer>
        </div>

        {allowsMotion && (
          <div
            css={css`
              ${column({
                widthMobile: 4,
                widthTablet: 6,
                widthDesktop: 8,
              })}
            `}
          >
            <PausePlayButton isPaused={isPaused} setIsPaused={setIsPaused} />
          </div>
        )}

        <button
          css={css`
            ${resets.button}
            ${typographyStyles.metaText}

            // Fallback for browsers that don't support gradients
            background-color: ${colours.gradientBase.mistyViolet};
            background: linear-gradient(
              to bottom right,
              ${colours.gradientBase.sunshine} 0%,
              ${colours.gradientBase.bubblegum} 60%
            );

            border-radius: ${borderRadii.m};
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            display: flex;
            overflow: hidden;
            position: absolute;
            z-index: 1;

            :focus {
              ${mixins.focused}
            }
          `}
          onClick={() => {
            scrollToHowItWorks(scrollRef.current, allowsMotion)
          }}
        >
          <div
            css={css`
              height: 30px;
              display: inline;
              padding-left: ${horizontal.s};
              padding-right: ${horizontal.xs};
              margin-top: auto;
              margin-bottom: auto;
            `}
          >
            <HandPointDownIcon height={iconSize.m} />
          </div>

          <div
            css={css`
              background-color: ${colours.white};
              padding: ${vertical.xs} ${horizontal.s};
            `}
          >
            {ctaCopy}
          </div>
        </button>
      </GridRow>
    </section>
  )
}
