import React from "react"
import styled from "@emotion/styled"

import {
  Body,
  Card as DKCard,
  CheckCircleIcon,
  Headline2,
  PrimaryLink,
  breakpoints,
  colours,
  horizontal,
  vertical,
} from "design-kit"

import TrustPilot from "../Trustpilot"

import mobileImg from "./people-tech-mobile.png"
import desktopImg from "./people-tech-desktop.png"

const CardContainer = styled(DKCard)`
  margin-bottom: ${vertical.xl};
`

const CardTitle = styled(Headline2)`
  padding-bottom: ${vertical.xs};
`

const CardContent = styled(Body)`
  color: ${colours.offBlack};
  padding: 0 0 ${vertical.xs} 0;

  ${breakpoints.tablet`
    padding-right: ${horizontal.xl};
  `}
`

const TickSection = styled.div`
  ${breakpoints.tablet`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `}
`

const TickItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding-bottom: ${vertical.xxs};

  > svg {
    flex-shrink: 0;
  }

  ${breakpoints.desktop`
    width: 25%;
  `}
`

const TickCopy = styled(Body)`
  color: ${colours.offBlack};
  margin-left: ${horizontal.xxs};
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${breakpoints.tablet`
    align-items: flex-end;
    flex-direction: row;
  `}
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${breakpoints.tablet`
    align-items: flex-start;
    flex-direction: row;
  `}
`

const ButtonContainer = styled.div`
  padding-bottom: ${vertical.s};
  padding-top: ${vertical.xxs};
  margin-right: ${horizontal.s};

  ${breakpoints.tablet`
    padding-right: ${horizontal.s};
    padding-bottom: 0;
  `}
`

const HR = styled.hr`
  border-top: 1px solid ${colours.greyScale.grey50};
  margin: ${vertical.s} 0;
`

const MobileImage = styled.img`
  max-height: 100px;
  max-width: 100%;
  padding-bottom: ${vertical.xs};

  ${breakpoints.tablet`
    display: none;
  `}
`

const DesktopImage = styled.img`
  display: none;

  ${breakpoints.tablet`
    display: flex;
    max-height: 200px;
  `}
`

interface AboutCardProps {
  title: string
  content: string
  href: string
}

const AboutCard: React.FC<AboutCardProps> = ({ title, content, href }) => (
  <CardContainer element="div">
    <FlexContainer>
      <MobileImage src={mobileImg} alt="" />

      <div>
        <CardTitle spacing="none">{title}</CardTitle>
        <CardContent>{content}</CardContent>
      </div>

      <DesktopImage src={desktopImg} alt="" />
    </FlexContainer>

    <RowContainer>
      <ButtonContainer>
        <PrimaryLink href={href} text="Get expert advice" />
      </ButtonContainer>

      <TrustPilot textMode="long" />
    </RowContainer>
    <HR />

    <TickSection>
      {[
        "FCA authorised",
        "Completely free",
        "Trusted by over 550,000 people",
        "No surprise credit checks",
      ].map((item, i) => (
        <TickItem key={i}>
          <CheckCircleIcon color={colours.constructive.dark} />
          <TickCopy spacing="none">{item}</TickCopy>
        </TickItem>
      ))}
    </TickSection>
  </CardContainer>
)

export default AboutCard
