import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import * as RD from "@heyhabito/remote-data"
import { FormSpec, FormProvider } from "@heyhabito/form-utils"
import { useSentry } from "@heyhabito/sentry"

import {
  BodySmall,
  BodySmallBold,
  borderRadii,
  breakpoints,
  colours,
  horizontal,
  InfoIcon,
  LinkCTA,
  mixins,
  PlusLogo,
  PrimaryTwoCTA,
  RadioButtonGroup,
  SelectInput,
  typographyStyles,
  vertical,
} from "design-kit"

import { useAmplitude } from "../../Shared/utils/amplitude"
import { LoadingBars } from "../../Shared/LoadingBars"

import { FormInner } from "../components/Form"
import { AnswerQuestionsTip } from "../components/AnswerQuestionsTip"
import { Section } from "../components/Section"
import { Card } from "../../../shared-components/Calculators/Card"
import { InputCard } from "../components/InputCard"
import { GradientSpan } from "../components/GradientSpan"
import {
  CollapsibleSection,
  CollapseState,
} from "../components/CollapsibleSection"
import { logUndefinedFields } from "../utils/forms"
import { ContentArea } from "../components/ContentArea"
import { getStampDuty, StampDutyResponse } from "../api"
import { useScrollAndFocusUpdates } from "../utils/focus"
import { Country } from "../types"
import {
  Pounds,
  mkPounds,
  penceToPounds,
  poundsToPence,
  BuyerType,
} from "../../../shared-components/Calculators/types"
import { formatCurrency } from "../../../shared-components/Calculators/formatting"

interface OtherCostsAnswers {
  country: Country
  isFirstTimeBuyer: boolean
}

interface Props {
  setOtherCostsAnswers: (oca: OtherCostsAnswers) => Promise<void>
  otherCostsAnswers: OtherCostsAnswers | null
  propertyValue: Pounds
  shouldScrollToSection: boolean
}

const SOLICITOR_MIN_COST: Pounds = mkPounds(1500)
const SOLICITOR_MAX_COST: Pounds = mkPounds(2000)
const SURVEY_COST: Pounds = mkPounds(555)

const visibleHeading = (
  <span css={css(typographyStyles.headline2)}>
    For all the other costs, you’ll pay
  </span>
)

const ids = {
  container: {
    heading: "habito-mortgage-calculator-newmo-other-costs-heading",
  },
  legalAndProperty: {
    description:
      "habito-mortgage-calculator-newmo-other-costs-legal-property-description",
    heading:
      "habito-mortgage-calculator-newmo-other-costs-legal-property-heading",
  },
  plus: {
    heading: "habito-mortgage-calculator-newmo-other-costs-plus-heading",
  },
  stampDuty: {
    description:
      "habito-mortgage-calculator-newmo-other-costs-stamp-duty-description",
    heading: "habito-mortgage-calculator-newmo-other-costs-stamp-duty-heading",
  },
}

const SubHeading = styled.h3(typographyStyles.headlineLarge)

const SubHeadingDescription = styled(BodySmall)`
  margin-top: ${vertical.xxs};
`

const Table = styled.table`
  width: 100%;

  thead {
    ${mixins.invisible}
  }

  caption {
    margin-bottom: ${vertical.xs};
    text-align: left;
  }
`

const StampDutyListRow = styled.li`
  display: flex;
  justify-content: space-between;
  padding-left: 18px;
  position: relative;
  margin-top: ${vertical.xs};

  :before {
    content: "";
    width: 12px;
    height: 12px;
    background-color: ${colours.callout.timeline};
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: -7px;
  }

  :not(:last-of-type) {
    :after {
      content: "";
      display: block;
      width: 2px;
      height: calc(100% + 6px);
      background-color: ${colours.callout.timeline};
      position: absolute;
      top: 16px;
      left: -2px;
    }
  }
`

interface AdditionalCostsAnswers {
  country: Country
  isFirstTimeBuyer: boolean
}

const formSpec: FormSpec<AdditionalCostsAnswers> = {
  country: {
    toError: () => {
      return null
    },
  },
  isFirstTimeBuyer: {
    toError: () => {
      return null
    },
  },
}

const PLUS_CTA_VISIBLE_TEXT = "Learn more"

const OtherCostsSection: React.VFC<Props> = ({
  setOtherCostsAnswers,
  otherCostsAnswers,
  propertyValue,
  shouldScrollToSection,
}) => {
  const [collapseState, setCollapseState] =
    React.useState<CollapseState>("expanded")

  const sectionRef = React.useRef<HTMLDivElement>(null)

  useScrollAndFocusUpdates({
    shouldScrollToSection: shouldScrollToSection,
    sectionRef,
  })

  const { logClickedLink } = useAmplitude(
    BuyerType.NewMortgage,
    "payments_section"
  )

  const { logToSentry } = useSentry()
  const { logSubmittedForm } = useAmplitude(
    BuyerType.NewMortgage,
    "other_costs_section"
  )

  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const [stampDutyRequest, setStampDutyRequest] = React.useState<
    RD.RemoteData<Error, StampDutyResponse>
  >(RD.NotAsked)

  const totalCosts =
    stampDutyRequest._tag === "Success"
      ? {
          min: mkPounds(
            stampDutyRequest.result.stampDuty.value / 100 +
              SOLICITOR_MIN_COST.value +
              SURVEY_COST.value
          ),
          max: mkPounds(
            stampDutyRequest.result.stampDuty.value / 100 +
              SOLICITOR_MAX_COST.value +
              SURVEY_COST.value
          ),
        }
      : undefined

  const fetchStampDuty = async (
    isFirstTimeBuyer: boolean,
    country: Country,
    propertyValue: Pounds
  ): Promise<void> => {
    try {
      const stampDuty = await getStampDuty({
        isFirstTimeBuyer,
        propertyValue: poundsToPence(propertyValue),
        country,
      })
      setStampDutyRequest(RD.Success(stampDuty))
    } catch (e) {
      const err = e instanceof Error ? e : new Error(`${e}`)
      setStampDutyRequest(RD.Failure(err))
      logToSentry("Error fetching stamp duty in NewMo OtherCostsSection", err)
    }
  }

  React.useEffect(() => {
    if (otherCostsAnswers) {
      fetchStampDuty(
        otherCostsAnswers.isFirstTimeBuyer,
        otherCostsAnswers.country,
        propertyValue
      )
    }
  }, [otherCostsAnswers, propertyValue])

  return (
    <Section label="Other costs" ref={sectionRef} tabIndex={-1}>
      <InputCard
        headingId="habito-mortgage-calculator-newmo-input-other-costs-heading"
        title="Additional costs"
      >
        <FormProvider
          formSpec={formSpec}
          showErrors={false}
          previousState={null}
          prepopulationAnswers={otherCostsAnswers || {}}
        >
          {(formState, extractAnswers) => (
            <FormInner>
              {formState.country && (
                <SelectInput
                  title="Where are you buying?"
                  options={{
                    England: "England",
                    Scotland: "Scotland",
                    Wales: "Wales",
                    "Northern Ireland": "Northern Ireland",
                  }}
                  selected={formState.country.value}
                  error={formState.country.error}
                  onChange={formState.country.onChange}
                />
              )}

              {formState.isFirstTimeBuyer && (
                <RadioButtonGroup
                  title="Are you a first-time buyer?"
                  name="first-time-buyer"
                  buttons={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" },
                  ]}
                  layout="row"
                  selectedValue={
                    formState.isFirstTimeBuyer.value === true
                      ? "yes"
                      : formState.isFirstTimeBuyer.value === false
                        ? "no"
                        : undefined
                  }
                  errorMessage={formState.isFirstTimeBuyer.error}
                  onChange={isFirstTimeBuyerStr => {
                    if (formState.isFirstTimeBuyer) {
                      formState.isFirstTimeBuyer.onChange(
                        isFirstTimeBuyerStr === "yes"
                      )
                    }
                  }}
                />
              )}

              <PrimaryTwoCTA
                text="Calculate"
                disabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={async () => {
                  const answers = extractAnswers()
                  if (!answers) return

                  const { country, isFirstTimeBuyer } = answers

                  if (country !== undefined && isFirstTimeBuyer !== undefined) {
                    logSubmittedForm({ country, isFirstTimeBuyer })

                    setIsSubmitting(true)

                    await setOtherCostsAnswers({ country, isFirstTimeBuyer })

                    setIsSubmitting(false)
                  } else {
                    logUndefinedFields(
                      logToSentry,
                      "Error submitting form in NewMo OtherCostsSection",
                      { country, isFirstTimeBuyer }
                    )
                  }
                }}
              />
            </FormInner>
          )}
        </FormProvider>
      </InputCard>

      <ContentArea>
        <Card aria-labelledby={ids.container.heading} element="article">
          <h2 css={css(mixins.invisible)} id={ids.container.heading}>
            How much you’ll pay for other costs
          </h2>

          {stampDutyRequest._tag !== "Success" || !totalCosts ? (
            <React.Fragment>
              <p
                css={css`
                  margin-bottom: ${vertical.m};
                `}
              >
                {visibleHeading}
              </p>

              <LoadingBars widths={["37%"]} />

              <AnswerQuestionsTip />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p>
                {visibleHeading}{" "}
                <span
                  css={css`
                    ${typographyStyles.statement4}

                    display: block;
                    margin-top: ${vertical.xxs};
                    margin-bottom: ${vertical.m};
                  `}
                >
                  around{" "}
                  <GradientSpan
                    aria-label={`${formatCurrency(
                      totalCosts.min
                    )} to ${formatCurrency(totalCosts.max)}`}
                  >
                    {formatCurrency(totalCosts.min)} &ndash;{" "}
                    {formatCurrency(totalCosts.max, { hasPoundSymbol: false })}
                  </GradientSpan>
                  <span css={css(mixins.invisible)}>.</span>
                </span>
              </p>

              <CollapsibleSection
                id="newmo--other-costs"
                collapseState={collapseState}
                setCollapseState={setCollapseState}
                addNegativeBottomMargin
              >
                <div
                  css={css`
                    background-color: ${colours.greyScale.grey25};
                    display: flex;
                    flex-direction: column;
                    margin: 0 -${horizontal.s};
                    padding: ${vertical.xs} ${horizontal.s} ${vertical.m};

                    ${breakpoints.tablet`
                    flex-direction: row;
                    margin: 0 -${horizontal.l};
                    padding: ${vertical.xs} ${horizontal.l} ${vertical.m};

                    > article {
                      flex-basis: 100%;
                    }
                  `}
                  `}
                >
                  <article aria-labelledby={ids.stampDuty.heading}>
                    <SubHeading id={ids.stampDuty.heading}>
                      Stamp duty land tax
                    </SubHeading>
                    <SubHeadingDescription id={ids.stampDuty.description}>
                      You’ll pay:
                    </SubHeadingDescription>

                    <p
                      css={css`
                        ${typographyStyles.headline2}
                      `}
                    >
                      {formatCurrency(
                        penceToPounds(stampDutyRequest.result.stampDuty)
                      )}
                    </p>

                    <div
                      css={css`
                        background-color: ${colours.white};
                        border-radius: ${borderRadii.s};
                        color: ${colours.offBlack};
                        margin-top: ${vertical.s};
                        padding: ${vertical.xs} ${horizontal.s};
                      `}
                    >
                      <div
                        css={css`
                          display: flex;
                        `}
                      >
                        <div
                          css={css`
                            max-width: ${horizontal.m};
                            padding-right: ${horizontal.xs};
                          `}
                        >
                          <InfoIcon />
                        </div>
                        <BodySmall>
                          Stamp duty is calculated in bands. You’ll only pay
                          each rate on that portion of your property price.
                        </BodySmall>
                      </div>

                      <ul
                        css={css`
                          margin-left: ${horizontal.xs};
                        `}
                      >
                        <StampDutyListRow>
                          <BodySmallBold>£0 – £125,000</BodySmallBold>
                          <BodySmall>0%</BodySmall>
                        </StampDutyListRow>
                        <StampDutyListRow>
                          <BodySmallBold>£125,001 – £250,000</BodySmallBold>
                          <BodySmall>2%</BodySmall>
                        </StampDutyListRow>
                        <StampDutyListRow>
                          <BodySmallBold>£250,001 – £925,000</BodySmallBold>
                          <BodySmall>5%</BodySmall>
                        </StampDutyListRow>
                        <StampDutyListRow>
                          <BodySmallBold>£925,001 – £1,500,000</BodySmallBold>
                          <BodySmall>10%</BodySmall>
                        </StampDutyListRow>
                        <StampDutyListRow>
                          <BodySmallBold>£1,500,001 +</BodySmallBold>
                          <BodySmall>12%</BodySmall>
                        </StampDutyListRow>
                      </ul>
                    </div>
                  </article>

                  <div>
                    <div
                      css={css`
                        background-color: ${colours.greyScale.grey50};
                        height: 2px;
                        margin: ${vertical.m} 0;

                        ${breakpoints.tablet`
                        height: 100%;
                        margin: 0 ${horizontal.l};
                        width: 2px;
                      `}
                      `}
                    />
                  </div>

                  <article aria-labelledby={ids.legalAndProperty.heading}>
                    <Table
                      aria-labelledby={ids.legalAndProperty.heading}
                      aria-describedby={ids.legalAndProperty.description}
                      css={css`
                        tbody tr {
                          ${typographyStyles.bodySmall}

                          :not(:first-of-type) td {
                            padding-top: ${vertical.xxs};
                          }

                          :not(:last-of-type) {
                            border-bottom: 1px solid ${colours.greyScale.grey50};

                            td {
                              padding-bottom: ${vertical.xxs};
                            }
                          }

                          td:not(:first-of-type) {
                            padding-left: ${horizontal.m};
                            text-align: right;
                          }
                        }
                      `}
                    >
                      <caption>
                        <SubHeading id={ids.legalAndProperty.heading}>
                          Legal and property costs
                        </SubHeading>

                        <SubHeadingDescription
                          id={ids.legalAndProperty.description}
                        >
                          You’ll need a conveyancing solicitor and a survey.
                        </SubHeadingDescription>
                      </caption>

                      <thead>
                        <tr>
                          <th scope="col">Service</th>

                          <th scope="col">Cost</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>Solicitor</td>

                          <td>
                            Anywhere from{" "}
                            <strong>
                              {`${formatCurrency(
                                SOLICITOR_MIN_COST
                              )}–${formatCurrency(SOLICITOR_MAX_COST)}`}
                            </strong>
                          </td>
                        </tr>

                        <tr>
                          <td>Surveyor</td>

                          <td>
                            A typical homebuyers survey is{" "}
                            <strong>{formatCurrency(SURVEY_COST)}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    <article
                      aria-labelledby={ids.plus.heading}
                      css={css`
                        background-color: ${colours.habitoPlus};
                        border-radius: ${borderRadii.s};
                        color: ${colours.white};
                        margin-top: ${vertical.s};
                        padding: ${vertical.xs} ${horizontal.s};
                      `}
                    >
                      <h4
                        aria-label={`Habito Plus, from ${formatCurrency(
                          mkPounds(2000)
                        )}`}
                        css={css`
                          align-items: center;
                          display: flex;
                          justify-content: space-between;

                          svg {
                            flex-shrink: 0;
                          }
                        `}
                        id={ids.plus.heading}
                      >
                        <PlusLogo color="currentColor" width={140} />

                        <span
                          css={css`
                            ${typographyStyles.bodySmallBold}

                            align-self: flex-end;
                            text-align: right;

                            ${breakpoints.tablet`
                              margin-left: ${horizontal.m};
                            `}
                          `}
                        >{`From ${formatCurrency(mkPounds(2000))}`}</span>
                      </h4>

                      <BodySmall
                        css={css`
                          margin-top: ${vertical.xxs};
                        `}
                      >
                        Sort everything in one place, and track it all in your
                        dashboard.
                      </BodySmall>

                      <LinkCTA
                        descriptiveText={
                          PLUS_CTA_VISIBLE_TEXT + " about Habito Plus"
                        }
                        css={css`
                          margin-top: ${vertical.xxs};

                          ${breakpoints.tablet`
                          margin-top: ${vertical.xs};
                        `}
                        `}
                        href="/home-buying"
                        target="_blank"
                        onClick={() => {
                          logClickedLink("plus_learn_more", "/home-buying")
                        }}
                        prominence="secondary"
                        text={PLUS_CTA_VISIBLE_TEXT}
                        width={{
                          desktop: "content-width",
                          mobile: "full-width",
                          tablet: "content-width",
                        }}
                      />
                    </article>
                  </article>
                </div>
              </CollapsibleSection>
            </React.Fragment>
          )}
        </Card>
      </ContentArea>
    </Section>
  )
}

export default OtherCostsSection
