import React from "react"

import auth0js from "auth0-js"

import GoogleIcn from "../views/SignUp/assets/google-logo.svg"
import FacebookIcn from "../views/SignUp/assets/facebook-logo.svg"
import {
  WrappedHead,
  WrappedBase,
} from "../shared-components/Shared/WrappedBase"
import { UserContext } from "../shared-components/Shared/UserProvider"

import config from "../../config.json"
import { css } from "@emotion/react"
import {
  ArrowLeftIcon,
  breakpoints,
  ChevronRightIcon,
  colours,
  column,
  GridRow,
  HabitoLogo,
  horizontal,
  PrimaryTwoCTA,
  SecondaryCTA,
  TertiaryCTA,
  TertiaryLink,
  vertical,
} from "design-kit"
import styled from "@emotion/styled"
import {
  BodySmallFullWidth,
  BodyText,
  CardTitle,
  CtaBox,
  EmailInput,
  Error,
  HorizontalLine,
  HorizontalLineText,
  Icon,
  PasswordInput,
  SocialButtonsContainer,
  UserEmailBold,
} from "../shared-components/Auth/OAuthComponent/Style"
import {
  TimelineCard,
  TimelineContent,
} from "../shared-components/Auth/SigninPage/TimelineCard"
import TimelineSlider from "../shared-components/Auth/SigninPage/TimelineSlider"
import { buildAuth0Config } from "../utils/auth0"
import { registerButtonClick } from "../utils/amplitude"
import { UtmContext } from "../utils/utm"
import { authFunctions } from "../shared-components/Auth/logic"
import { useSentry } from "@heyhabito/sentry"

const RedirectToMyProperties: React.FC = () => {
  React.useEffect(() => {
    window.location.href = "/my-properties"
  }, [])

  return null
}

type WidgetState =
  | {
      tag: "initialScreen"
      email: string
    }
  | {
      tag: "continuingViaEmail"
      email: string
      isBadEmail: boolean
    }
  | {
      tag: "magicLinkSent"
      email: string
    }
  | {
      tag: "usingPasswordInstead"
      email: string
      password: string
      passwordErrorMessage: "wrongEmailOrPassword" | "somethingWentWrong" | null
    }
  | {
      tag: "passwordResetSent"
      email: string
    }

const trackingPageName = "signin_page"

const LoginWidget: React.FC = () => {
  const [state, setState] = React.useState<WidgetState>({
    tag: "initialScreen",
    email: "",
  })

  const { logToSentry } = useSentry()

  const utmMap = React.useContext(UtmContext)

  const [auth0Client, setAuth0Client] = React.useState<auth0js.WebAuth | null>(
    null
  )
  const [timeoutId, setTimeoutId] = React.useState<ReturnType<
    typeof setTimeout
  > | null>(null)

  React.useEffect(() => {
    const newTimeoutId = setTimeout(() => {
      if (
        typeof window !== "undefined" &&
        typeof window.HABITO_CONFIG !== "undefined" &&
        typeof window.HABITO_CONFIG.auth0Config !== "undefined" &&
        auth0Client === null
      ) {
        const auth0 = new auth0js.WebAuth(
          buildAuth0Config({
            baseAuth0Config: window.HABITO_CONFIG.auth0Config,
            marketingOptIn: null,
            orientationId: null,
            utmMap: utmMap,
            destination: null,
          })
        )
        setAuth0Client(auth0)
      }
    }, 200)

    setTimeoutId(newTimeoutId)

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utmMap])

  if (auth0Client === null) return null

  const { sendMagicLink, handleOAuth, passwordLogin, resetPassword } =
    authFunctions(trackingPageName, auth0Client, registerButtonClick)

  return (
    <React.Fragment>
      <GoBackBtn
        text="Go back"
        width="content-width"
        icon={{
          kind: "left",
          icon: <ArrowLeftIcon width={16} />,
        }}
        onClick={(() => {
          switch (state.tag) {
            case "initialScreen":
              return () => {
                window.history.back()
              }
            case "continuingViaEmail":
              return () => {
                setState({ ...state, tag: "initialScreen" })
              }
            case "magicLinkSent":
              return () => {
                setState({
                  ...state,
                  tag: "continuingViaEmail",
                  isBadEmail: false,
                })
              }
            case "usingPasswordInstead":
              return () => {
                setState({
                  ...state,
                  tag: "continuingViaEmail",
                  isBadEmail: false,
                })
              }
            case "passwordResetSent":
              return () => {
                setState({
                  ...state,
                  tag: "usingPasswordInstead",
                  password: "",
                  passwordErrorMessage: null,
                })
              }
          }
        })()}
      />

      {(() => {
        switch (state.tag) {
          case "initialScreen":
            return (
              <React.Fragment>
                <CardTitle>Log in to your account</CardTitle>
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    setState({
                      ...state,
                      tag: "continuingViaEmail",
                      isBadEmail: false,
                    })
                  }}
                >
                  <EmailInput
                    type="email"
                    id="email"
                    placeholder="Email address"
                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$"
                    title="Invalid email address"
                    required
                    value={state.email}
                    onChange={e =>
                      setState({ ...state, email: e.target.value })
                    }
                  />

                  <CtaBox>
                    <PrimaryTwoCTA
                      icon={{
                        kind: "right",
                        icon: <ChevronRightIcon height={16} />,
                      }}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onClick={() => {}} // form onSubmit is used instead
                      type="submit"
                      text="Continue"
                    />
                  </CtaBox>
                </form>

                <BodySmallFullWidth>
                  <HorizontalLine />
                  <HorizontalLineText>OR</HorizontalLineText>
                  <HorizontalLine />
                </BodySmallFullWidth>

                <SocialButtonsContainer>
                  <SecondaryCTA
                    onClick={handleOAuth("google-oauth2")}
                    icon={{
                      kind: "left",
                      icon: <Icon src={GoogleIcn} alt="" />,
                    }}
                    text="Continue with Google"
                    width="full-width"
                  />

                  <SecondaryCTA
                    onClick={handleOAuth("facebook")}
                    icon={{
                      kind: "left",
                      icon: <Icon src={FacebookIcn} alt="" />,
                    }}
                    text="Continue with Facebook"
                    width="full-width"
                  />
                </SocialButtonsContainer>

                <TertiaryLink
                  text="No account? Get started here"
                  href="/how-can-we-help"
                  width="content-width"
                />
              </React.Fragment>
            )
          case "continuingViaEmail":
            return (
              <React.Fragment>
                <CardTitle>
                  Log in with a magic link{" "}
                  <span role="img" aria-hidden>
                    &#10024;
                  </span>
                </CardTitle>

                <form
                  onSubmit={sendMagicLink(
                    state.email,
                    () => setState({ ...state, tag: "magicLinkSent" }),
                    e => {
                      if (e.code === "bad.email") {
                        setState({ ...state, isBadEmail: true })
                      } else {
                        logToSentry(
                          "Error sending magic link from log-in page",
                          {
                            message: "",
                            name: "",
                            ...e,
                          }
                        )
                      }
                    }
                  )}
                >
                  <BodyText>
                    Skip the password - we’ll email you a link to log in.
                  </BodyText>
                  <UserEmailBold>{state.email}</UserEmailBold>
                  <CtaBox>
                    <PrimaryTwoCTA
                      icon={{
                        kind: "right",
                        icon: <ChevronRightIcon height={16} />,
                      }}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onClick={() => {}} // form onSubmit is used instead
                      type="submit"
                      text="Send me a magic link"
                    />
                  </CtaBox>
                  {state.isBadEmail && (
                    <Error>
                      We couldn't send an email to that address. Please check
                      the address and try again.
                    </Error>
                  )}
                  <TertiaryCTA
                    onClick={() => {
                      setState({
                        ...state,
                        tag: "usingPasswordInstead",
                        password: "",
                        passwordErrorMessage: null,
                      })
                    }}
                    text="Use password instead"
                    width="content-width"
                  />
                </form>
              </React.Fragment>
            )
          case "magicLinkSent":
            return (
              <React.Fragment>
                <CardTitle>Check your email</CardTitle>
                <BodyText>
                  We’ve sent an email to
                  <UserEmailBold>{state.email}</UserEmailBold>
                </BodyText>
                <BodyText>Click it to log in or create an account</BodyText>
                <TertiaryCTA
                  onClick={() => {
                    setState({
                      ...state,
                      tag: "initialScreen",
                      email: "",
                    })
                  }}
                  text="Use a different email"
                  width="content-width"
                />
              </React.Fragment>
            )
          case "usingPasswordInstead":
            return (
              <React.Fragment>
                <CardTitle>Type your password</CardTitle>
                <form
                  onSubmit={passwordLogin(state.email, state.password, e => {
                    if (
                      e?.code === "access_denied" &&
                      (e?.description === "Wrong email or password." ||
                        /* When Auth0 tries to authenticate a user against a
                         * password hash that we've imported, Auth0 can't tell
                         * the difference between an incorrect password and an
                         * incorrectly imported hash.
                         *
                         * We're going to *assume* that the hash has been
                         * imported correctly, and tell the user they've got the
                         * wrong password. */
                        e?.description === "Password change required.")
                    ) {
                      setState({
                        ...state,
                        passwordErrorMessage: "wrongEmailOrPassword",
                      })
                    } else {
                      logToSentry(
                        "Error calling passwordLogin from log-in page",
                        { message: "", name: "", ...e }
                      )
                    }
                  })}
                >
                  <PasswordInput
                    type="password"
                    placeholder="Enter Password"
                    name="password"
                    id="password"
                    pattern=".{9,}"
                    title="Password must be at least 9 characters"
                    required
                    value={state.password}
                    onChange={e =>
                      setState({ ...state, password: e.target.value })
                    }
                    autoComplete="on"
                  />
                  {state.passwordErrorMessage && (
                    <Error>
                      {(() => {
                        switch (state.passwordErrorMessage) {
                          case "wrongEmailOrPassword":
                            return "Wrong email or password. Please try again."
                          case "somethingWentWrong":
                            return "Oops! Something went wrong."
                        }
                      })()}
                    </Error>
                  )}
                  <BodyText>Password must be at least 9 characters</BodyText>
                  <CtaBox>
                    <PrimaryTwoCTA
                      icon={{
                        kind: "right",
                        icon: <ChevronRightIcon height={16} />,
                      }}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onClick={() => {}} // form onSubmit is used instead
                      type="submit"
                      text="Continue"
                    />
                  </CtaBox>
                </form>

                <TertiaryCTA
                  onClick={() => {
                    resetPassword(state.email, err => {
                      setState({
                        ...state,
                        passwordErrorMessage: "somethingWentWrong",
                      })
                      logToSentry(
                        "Error calling resetPassword from log-in page",
                        { message: "", name: "", ...err }
                      )
                    })
                    setState({ ...state, tag: "passwordResetSent" })
                  }}
                  text="Forgot your password? Reset it"
                  width="content-width"
                />

                <TertiaryCTA
                  onClick={() =>
                    setState({
                      ...state,
                      tag: "continuingViaEmail",
                      isBadEmail: false,
                    })
                  }
                  text="Or use a magic link instead"
                  width="content-width"
                />
              </React.Fragment>
            )
          case "passwordResetSent":
            return (
              <React.Fragment>
                <CardTitle>
                  Check your inbox{" "}
                  <span role="img" aria-hidden>
                    &#x1F440;
                  </span>
                </CardTitle>

                <BodyText>
                  We’ve sent an email to{" "}
                  <UserEmailBold>{state.email}</UserEmailBold>
                </BodyText>
                <BodyText>Click the link to reset your password.</BodyText>

                <BodyText>Had enough of passwords?</BodyText>

                <TertiaryCTA
                  onClick={() =>
                    setState({
                      ...state,
                      tag: "continuingViaEmail",
                      isBadEmail: false,
                    })
                  }
                  text="Use a magic link instead"
                  width="content-width"
                />

                <TertiaryCTA
                  onClick={() =>
                    setState({
                      ...state,
                      tag: "initialScreen",
                      email: "",
                    })
                  }
                  text="Use a different email address"
                  width="content-width"
                />
              </React.Fragment>
            )
        }
      })()}
    </React.Fragment>
  )
}

const LogoContainer = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })}

  padding: ${vertical.xs} 0;
`

const Main = styled.main`
  padding-top: ${vertical.l};
  padding-bottom: ${vertical.xl};
`

const FlexContainer = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })}

  ${breakpoints.tablet`
    display: flex;
    justify-content: space-evenly;
  `}
`

const LoginWidgetContainer = styled.div`
  max-width: 330px;
  padding: 0 ${horizontal.s};
  margin: 0 auto ${vertical.m} auto;

  ${breakpoints.tablet`
    padding: 0;
    margin: 0 ${horizontal.l} 0 0;
  `}
`

const GoBackBtn = styled(TertiaryCTA)`
  margin-bottom: ${vertical.m};
`

const TimelineContainer = styled.div`
  display: none;

  ${breakpoints.tablet`
    display: flex;
    max-width: 400px;
  `}
`

const MobileStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 0;

  ${breakpoints.tablet`
    display: none;
  `}
`

export const Head: React.FC = () => {
  return (
    <WrappedHead
      metaTitle="Habito | Log in"
      metaDescription="Log in to Habito - the UK's free online mortgage broker, making mortgage worries history. Our smart technology searches the whole market - thousands of mortgage products from over 90 lenders - to get you the perfect mortgage."
      canonicalUrl="https://www.habito.com/log-in"
      noIndex={true}
      pageName="login_page"
      intercom={false}
      config={config}
    />
  )
}

const IndexPage: React.FC = () => {
  return (
    <WrappedBase>
      <UserContext.Consumer>
        {user =>
          user ? (
            <RedirectToMyProperties />
          ) : (
            <React.Fragment>
              <GridRow
                css={css`
                  border-bottom: 1px solid ${colours.greyScale.grey75};
                `}
              >
                <LogoContainer>
                  <HabitoLogo />
                </LogoContainer>
              </GridRow>

              <Main id="login_page">
                <GridRow>
                  <FlexContainer>
                    <LoginWidgetContainer>
                      <LoginWidget />
                    </LoginWidgetContainer>
                    {/* The TimelineContainer will only render on desktop/tablet */}
                    <TimelineContainer>
                      <TimelineCard />
                    </TimelineContainer>
                  </FlexContainer>
                </GridRow>
                {/* The TimelineSlider will only render on mobile */}
                <MobileStyle>
                  <TimelineSlider title="How Habito works">
                    <TimelineContent />
                  </TimelineSlider>
                </MobileStyle>
              </Main>
            </React.Fragment>
          )
        }
      </UserContext.Consumer>
    </WrappedBase>
  )
}

export default IndexPage
