import React from "react"
import styled from "@emotion/styled"

import {
  WrappedHead,
  WrappedBase,
} from "../shared-components/Shared/WrappedBase"
import { UserContext } from "../shared-components/Shared/UserProvider"
import Footer from "../shared-components/Footer"
import Navbar from "../shared-components/Navbar"
import Header from "../views/MIP/Header"
import Overview from "../views/MIP/Overview"
import Journey from "../views/MIP/Journey"
import HabitoCanSortYou from "../views/MIP/HabitoCanSortYou"
import GetStarted from "../views/MIP/GetStarted"
import FAQ from "../views/MIP/FAQ"

import config from "../../config.json"
import { registerButtonClick } from "../utils/amplitude"

const pageName = "mortgage_in_principle"

const BorderBoxContainer = styled.div`
  * {
    box-sizing: border-box;
  }
`

const mipSchema = (
  <script type="application/ld+json">{`
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is a mortgage in principle (MIP)?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>An MIP is a certificate showing how much you can borrow on your mortgage.</p><br/><p>You can take it to property viewings, and use it to show sellers that:</p><ul><li>you’ve done your homework</li><li>you’re serious about buying</li><li>and you can afford to buy</li></ul><br/><p>It’s also a good way to reassure yourself, too. Your MIP will give you a sense of whether you can afford to borrow the amount you were hoping for. It’s a rough estimate, and not necessarily a guarantee, but it’s a good first step.</p><br/><p>An MIP is different from an agreement in principle (AIP).</p>"
    }
  }, {
    "@type": "Question",
    "name": "Do I need to get a credit check to get an MIP?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Nope. There’s no credit check needed for a mortgage in principle. <br /><br />Later on, when it’s time to actually apply for a mortgage, you might have to do a credit check. But you’ll need to give your express consent before that can happen, so it will never be a surprise."
    }
  }, {
    "@type": "Question",
    "name": "How reliable is an MIP? Does it guarantee I'll get a mortgage?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "If only. The truth is an MIP is no guarantee you’ll get a mortgage.<br /><br />An MIP is a basic check of what you can afford to borrow, based on a few questions about your income and deposit. There’s no credit check.<br /><br />Later on in your mortgage journey, you’ll have to answer more detailed questions about your finances, and do a credit check to get an exact figure. That might change the amount you can borrow."
    }
  }, {
    "@type": "Question",
    "name": "Do I have to get an MIP?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Nope. If you wanted, you could skip it and go straight to your mortgage application.<br /><br />An MIP is useful if you want more certainty about what you can borrow – for very little effort – before committing to a full mortgage application, credit check and all.<br /><br />It’s useful, but not compulsory."
    }
  }, {
    "@type": "Question",
    "name": "Does a mortgage in principle affect your credit score?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text":"No, a mortgage in principle won’t affect your credit score. That’s because it uses a basic affordability calculation without doing a credit check.<br /><br />If you’re getting an agreement in principle from a lender, on the other hand, they will do a credit check, but it’s almost always going to be a soft credit check, which won’t affect your credit score."}
    }]
}
`}</script>
)

const MIPContent: React.FC = () => (
  <UserContext.Consumer>
    {user => (
      <BorderBoxContainer>
        <Navbar theme="transparent" />

        <main id="main-content">
          <Header
            isLoggedIn={Boolean(user)}
            registerButtonClick={registerButtonClick(pageName)}
          />
          <Overview
            isLoggedIn={Boolean(user)}
            registerButtonClick={registerButtonClick(pageName)}
          />
          <Journey />
          <FAQ />
          <HabitoCanSortYou />
          <GetStarted
            isLoggedIn={Boolean(user)}
            registerButtonClick={registerButtonClick(pageName)}
          />
        </main>

        <Footer />
      </BorderBoxContainer>
    )}
  </UserContext.Consumer>
)

export const Head: React.FC = () => (
  <WrappedHead
    metaTitle="Get a mortgage in principle online, free! | Habito"
    metaDescription="Show sellers you're serious with an MIP. It's totally free, no credit check needed. Get yours right now."
    canonicalUrl="https://www.habito.com/mortgage-in-principle"
    noIndex={false}
    pageName={pageName}
    intercom={false}
    config={config}
  >
    {mipSchema}
  </WrappedHead>
)

const MIPLandingPage: React.FC = () => (
  <WrappedBase>
    <MIPContent />
  </WrappedBase>
)

export default MIPLandingPage
