import React from "react"

import styled from "@emotion/styled"
import { BodySmallBold, TrustpilotLogo, colours } from "design-kit"
import { css } from "@emotion/react"
import { useTrustpilot } from "./hooks"

const TrustpilotContainer = styled.div`
  display: flex;
  flex-direction: column;
`

interface Props {
  textMode: "short" | "long"
  className?: string
}

const View: React.FunctionComponent<Props> = ({ className, textMode }) => {
  const { stars, description, numberOfReviewsFormatted } = useTrustpilot()
  return (
    <TrustpilotContainer className={className}>
      <a
        href="https://uk.trustpilot.com/review/habito.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TrustpilotLogo width={200} stars={stars} />
      </a>

      <BodySmallBold
        css={css`
          color: ${colours.offBlack};
        `}
      >
        {textMode === "short"
          ? `${numberOfReviewsFormatted}+ customer reviews`
          : `Rated ${description} based on over ${numberOfReviewsFormatted} customer reviews`}
      </BodySmallBold>
    </TrustpilotContainer>
  )
}

export default View
