import { FC } from "react"
import styled from "@emotion/styled"

import {
  GridRow,
  Headline1,
  breakpoints,
  colours,
  column,
  typographyStyles,
  vertical,
} from "design-kit"

import keys from "./keys.png"

const Inner = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })}

  padding-top: ${vertical.xxl};
  padding-bottom: ${vertical.s};

  ${breakpoints.tablet`
    background-image: url(${keys});
    background-repeat: no-repeat;
    background-position: right;
    background-size: 210px auto;
    margin-top: ${vertical.l};
  `}

  ${breakpoints.desktop`
    background-size: 250px auto;
  `}
`

const Heading = styled(Headline1)`
  color: ${colours.offBlack};

  ${breakpoints.tablet`
    max-width: 60%;
  `}
`

const CopyContainer = styled.div`
  ${typographyStyles.body}

  color: ${colours.offBlack};
  margin: ${vertical.xs} 0;

  ${breakpoints.tablet`
    margin: ${vertical.m} 0;
    max-width: 70%;
  `};
`

const Hero: FC<{ title: string }> = ({ title, children }) => (
  <GridRow>
    <Inner>
      <Heading spacing="none">{title}</Heading>

      <CopyContainer>{children}</CopyContainer>
    </Inner>
  </GridRow>
)

export default Hero
