import * as React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { horizontal, colours, typographyStyles } from "../index"

export type TagType =
  | "neutral"
  | "positive"
  | "pending"
  | "progress"
  | "negative"

interface InternalProps {
  kind: TagType
  className?: string
}

export const baseTagStyles = css`
  ${typographyStyles.metaText};
  height: 24px;
  line-height: 24px;
  display: inline-block;
  padding: 0 ${horizontal.s};
  text-align: center;
  border-radius: 100px;
  box-sizing: border-box;
  white-space: nowrap;
  color: ${colours.offBlack};
`

const InternalTag = styled.div<InternalProps>`
  ${baseTagStyles}
  background: ${props => toBackgroundColor(props)};
`

const toBackgroundColor = (props: InternalProps): string => {
  switch (props.kind) {
    case "neutral":
      return colours.callout.neutral
    case "positive":
      return colours.callout.positive
    case "pending":
      return colours.callout.pending
    case "progress":
      return colours.callout.progress
    case "negative":
      return colours.callout.negative
  }
}

type Props = InternalProps & {
  text: string
}

export const Tag: React.FC<Props> = props => (
  <InternalTag {...props}>{props.text}</InternalTag>
)
